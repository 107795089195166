import React from "react";

export const surfaceConfigs = [
  {
    path: "/mui/surface/appbar",
    component: React.lazy(() => import("./AppBar")),
  },
  {
    path: "/mui/surface/accordion",
    component: React.lazy(() => import("./Accordion")),
  },
  {
    path: "/mui/surface/cards",
    component: React.lazy(() => import("./Card")),
  },
  {
    path: "/mui/surface/paper",
    component: React.lazy(() => import("./Paper")),
  },
];
