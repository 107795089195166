export const authRole = {
  admin: ["admin"],
  user: ["user", "admin"],
};

export enum AbilitiActions {
  VIEW = "VIEW",
  EDIT = "EDIT",
}

export enum AbilityResources {
  EVENT = "EVENT",
  OPERATOR = "OPERATOR",
  OPERATOR_ROLE = "OPERATOR_ROLE",
  EMAIL = "EMAIL",
  OPPONENT_API = "OPPONENT_API",
  BETAPI = "BETAPI",
  CLIENT = "CLIENT",
  CONTENT = "CONTENT",
  SYSTEM = "SYSTEM",
  FINANCE = "FINANCE",
}

export const initialUrl = "/dashboard"; // this url will open after login
