// ForJWT Auth
import {
  useJWTAuth,
  useJWTAuthActions,
} from "../services/auth/jwt-auth/jwt-auth-provider";

export const useAuthUser = () => {
  const { user, isAuthenticated, isLoading } = useJWTAuth();
  return {
    isLoading,
    isAuthenticated,
    user,
  };
};

export const useAuthMethod = () => {
  const { signInUser, logout } = useJWTAuthActions();

  return {
    signInUser,
    logout,
  };
};
