import { gql } from "@apollo/client";
import type { GraphqlErrorType } from "application/graphql/graphql-types";
import {
  OperatorAbilityModel,
  OperatorModel,
  OperatorRoleModel,
} from "application/graphql/user-context";

export interface GetMyOperatorMutation {
  getMyOperator:
    | {
        __typename: "GetMyOperator";
        operator: OperatorModel;
        role: OperatorRoleModel;
        abilities: OperatorAbilityModel[];
      }
    | GraphqlErrorType;
}

export interface GetMyOperatorMutationVars {}

const GET_MY_OPERATOR = gql`
  query GetMyOperator {
    getMyOperator {
      operator {
        id
        email
        name
      }
      role {
        name
      }
      abilities {
        action
        resource
      }
    }
  }
`;

export default GET_MY_OPERATOR;
