import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

const InjectMassage = (props) => {
  const { lang, ...rest } = props;

  return (
    <FormattedMessage {...rest}>
      {(translatedString) => `${translatedString}${lang ? ` (${lang})` : ""}`}
    </FormattedMessage>
  );
};

export default injectIntl(InjectMassage, {
  forwardRef: false,
});
