import React from "react";

export const userListConfig = [
  {
    path: "/list-type/flat",
    component: React.lazy(() => import("./Flat/index")),
  },
  {
    path: "/list-type/morden",
    component: React.lazy(() => import("./Morden/index")),
  },
  {
    path: "/list-type/standard",
    component: React.lazy(() => import("./Standard/index")),
  },
];
