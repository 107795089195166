import { ruRU } from "@mui/material/locale";
import ruMessages from "../locales/ru_RU.json";

const EnLang = {
  messages: {
    ...ruMessages,
  },
  muiLocale: ruRU,
  locale: "ru-RU",
};
export default EnLang;
