import React from "react";

const systemRouteConfig = [
  {
    path: "/system/money",
    component: React.lazy(() => import("./pages/money-system-page")),
  },
  {
    path: "/system/pari",
    component: React.lazy(() => import("./pages/pari-system-page")),
  },
  {
    path: "/system/live",
    component: React.lazy(() => import("./pages/live-system-page")),
  },
  {
    path: "/system/affiliate",
    component: React.lazy(() => import("./pages/affiliate-system-page")),
  },
];

export default systemRouteConfig;
