import React from "react";

export const inputsConfigs = [
  {
    path: "/mui/inputs/autocomplete",
    component: React.lazy(() => import("./AutoComplete")),
  },
  {
    path: "/mui/inputs/buttons",
    component: React.lazy(() => import("./Buttons")),
  },
  {
    path: "/mui/inputs/button-group",
    component: React.lazy(() => import("./ButtonGroup")),
  },
  {
    path: "/mui/inputs/checkboxes",
    component: React.lazy(() => import("./Checkboxes")),
  },
  {
    path: "/mui/inputs/floating-action-button",
    component: React.lazy(() => import("./FloatingActionButton")),
  },
  {
    path: "/mui/inputs/radios",
    component: React.lazy(() => import("./Radio")),
  },
  {
    path: "/mui/inputs/rating",
    component: React.lazy(() => import("./Rating")),
  },
  {
    path: "/mui/inputs/selects",
    component: React.lazy(() => import("./Selects")),
  },
  {
    path: "/mui/inputs/slider",
    component: React.lazy(() => import("./Slider")),
  },
  {
    path: "/mui/inputs/switches",
    component: React.lazy(() => import("./Switches")),
  },
  {
    path: "/mui/inputs/text-fields",
    component: React.lazy(() => import("./TextField")),
  },
  {
    path: "/mui/inputs/transfer-list",
    component: React.lazy(() => import("./TransferList")),
  },
  {
    path: "/mui/inputs/toggle-buttons",
    component: React.lazy(() => import("./ToggleButtons")),
  },
];
