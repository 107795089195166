import React from "react";

export const dataDisplayConfigs = [
  {
    path: "/mui/data-display/avatars",
    component: React.lazy(() => import("./Avatar")),
  },
  {
    path: "/mui/data-display/badges",
    component: React.lazy(() => import("./Badges")),
  },
  {
    path: "/mui/data-display/chips",
    component: React.lazy(() => import("./Chips")),
  },
  {
    path: "/mui/data-display/divider",
    component: React.lazy(() => import("./Divider")),
  },
  {
    path: "/mui/data-display/lists",
    component: React.lazy(() => import("./Lists")),
  },
  {
    path: "/mui/data-display/tables",
    component: React.lazy(() => import("./Table")),
  },
  {
    path: "/mui/data-display/tooltip",
    component: React.lazy(() => import("./Tooltip")),
  },

  {
    path: "/mui/data-display/divider",
    component: React.lazy(() => import("./Divider")),
  },
];
