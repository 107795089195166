import React from "react";

export const utilConfigs = [
  {
    path: "/mui/utility/click-away-listener",
    component: React.lazy(() => import("./ClickawayListener")),
  },
  {
    path: "/mui/utility/modal/",
    component: React.lazy(() => import("./Modal")),
  },
  {
    path: "/mui/utility/NoSSR1/",
    component: React.lazy(() => import("./NoSSR1")),
  },
  {
    path: "/mui/utility/Popover/",
    component: React.lazy(() => import("./Popover")),
  },
  {
    path: "/mui/utility/Popper/",
    component: React.lazy(() => import("./Popper")),
  },
  {
    path: "/mui/utility/Portal/",
    component: React.lazy(() => import("./Portal")),
  },
  {
    path: "/mui/utility/Transitions/",
    component: React.lazy(() => import("./Transitions")),
  },
  {
    path: "/mui/utility/MediaQuery/",
    component: React.lazy(() => import("./MediaQuery")),
  },
];
