import React from "react";

export const ecommerceConfig = [
  {
    path: "/ecommerce/invoice-1",
    component: React.lazy(() => import("./Invoice1")),
  },

  {
    path: "/ecommerce/invoice-2",
    component: React.lazy(() => import("./Invoice2")),
  },

  {
    path: "/ecommerce/invoice-3",
    component: React.lazy(() => import("./Invoice3")),
  },

  {
    path: "/ecommerce/products",
    component: React.lazy(() => import("./Products")),
  },

  {
    path: "/ecommerce/product_detail/:id?",
    component: React.lazy(() => import("./ProductDetail")),
  },

  {
    path: "/ecommerce/customers",
    component: React.lazy(() => import("./Customers")),
  },

  {
    path: "/ecommerce/checkout",
    component: React.lazy(() => import("./Checkout")),
  },

  {
    path: "/ecommerce/cart",
    component: React.lazy(() => import("./Carts")),
  },

  {
    path: "/ecommerce/orders",
    component: React.lazy(() => import("./Orders")),
  },

  {
    path: "/ecommerce/confirmation",
    component: React.lazy(() => import("./Confirmation")),
  },
];
