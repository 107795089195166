import React from "react";

export const navigationConfigs = [
  {
    path: "/mui/navigation/bottom-navigation",
    component: React.lazy(() => import("./BottomNavigation")),
  },
  {
    path: "/mui/navigation/breadcrumbs",
    component: React.lazy(() => import("./Breadcrumbs")),
  },
  {
    path: "/mui/navigation/drawers",
    component: React.lazy(() => import("./Drawer")),
  },
  {
    path: "/mui/navigation/links",
    component: React.lazy(() => import("./Links")),
  },
  {
    path: "/mui/navigation/menus",
    component: React.lazy(() => import("./Menu")),
  },
  {
    path: "/mui/navigation/pagination",
    component: React.lazy(() => import("./Pagination")),
  },
  {
    path: "/mui/navigation/speed-dial",
    component: React.lazy(() => import("./SpeedDial")),
  },
  {
    path: "/mui/navigation/steppers",
    component: React.lazy(() => import("./Stepper")),
  },
  {
    path: "/mui/navigation/tabs",
    component: React.lazy(() => import("./Tabs")),
  },
];
