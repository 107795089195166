import React from "react";

export const helpRouteConfig = [
  {
    path: "/help/categories/:language?/:category?",
    component: React.lazy(() => import("./pages/help-categories-page")),
  },
  {
    path: "/help/answers/:language?/:category?/:answer?",
    component: React.lazy(() => import("./pages/help-answers-page")),
  },
  {
    path: "/help/questions/:language?/:question?",
    component: React.lazy(() => import("./pages/help-questions-page")),
  },
];
