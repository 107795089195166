import React from "react";

export const dashBoardConfigs = [
  {
    path: "/dashboards/academy",
    component: React.lazy(() => import("./Academy")),
  },
  {
    path: "/dashboards/analytics",
    component: React.lazy(() => import("./Analytics")),
  },
  {
    path: "/dashboards/e-commerce",
    component: React.lazy(() => import("./ECommerce")),
  },
  {
    path: "/dashboards/crm",
    component: React.lazy(() => import("./CRM")),
  },
  {
    path: "/dashboards/health-care",
    component: React.lazy(() => import("./HealthCare")),
  },
  {
    path: "/dashboards/crypto",
    component: React.lazy(() => import("./Crypto")),
  },
  {
    path: "/dashboards/metrics",
    component: React.lazy(() => import("./Metrics")),
  },
  {
    path: "/dashboards/widgets",
    component: React.lazy(() => import("./Widgets")),
  },
];
