import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { apiConfig } from "config";

const enhancedFetch = (url: RequestInfo, init: RequestInit) => {
  return fetch(url, {
    ...init,
    headers: {
      ...init.headers,
      "x-locale": "ru",
    },
  }).then((response) => response);
};

const apolloClient = new ApolloClient({
  ssrMode: typeof window === "undefined",
  link: ApolloLink.from([
    createUploadLink({
      uri: apiConfig.grpahqlUrl,
      fetchOptions: {
        mode: "cors",
      },
      credentials: "include",
      fetch: enhancedFetch,
    }),
  ]),
  cache: new InMemoryCache(),
});

export default apolloClient;
