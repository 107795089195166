import React from "react";
import { initialUrl } from "shared/constants/AppConst";
import { authRouteConfig } from "features/auth";
import { eventsRouteConfig } from "features/events";

import { usersRouteConfig } from "features/clients";
import { betapiRouteConfig } from "features/betapi";
import { contentRouteConfig } from "features/content-pages";
import { helpRouteConfig } from "features/help";
import { financeSettingsRouteConfig } from "features/finance";
import { newsRouteConfig } from "features/news";
import Error403 from "./errorPages/Error403";
import { dashBoardConfigs } from "./dashboards";
import { errorPagesConfigs } from "./errorPages";
import { thirdPartyConfigs } from "./thirdParty";
import { extraPagesConfigs } from "./extraPages";
import { muiComponentConfigs } from "./muiComponents";
import { userPagesConfig } from "./userPages";
import { userListConfig } from "./userList";
import { appsConfig } from "./apps";
import { ecommerceConfig } from "./ecommerce";
import { dashboardRouteConfig } from "features/dashboard";
import { accountRouteConfig } from "features/account";
import operatorsRouteConfig from "features/operators";
import emailsRouteConfig from "features/emails";
import systemRouteConfig from "features/system";
import { affiliatesRouteConfig } from "features/affiliates";
import { opponentApiRouteConfig } from "features/opponent-api";
import { commentsRouteConfig } from "features/comments";
import { iqRatingRouteConfig } from "features/iq-rating";
import { newsCategoriesRouteConfig } from "../features/news-categories";
import {newsParametersRouteConfig} from "../features/news-parameters";

const authorizedStructure = {
  fallbackPath: "/signin",
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...dashboardRouteConfig,
    ...iqRatingRouteConfig,
    ...systemRouteConfig,
    ...accountRouteConfig,
    ...eventsRouteConfig,
    ...operatorsRouteConfig,
    ...emailsRouteConfig,

    ...usersRouteConfig,
    ...affiliatesRouteConfig,

    ...opponentApiRouteConfig,
    ...betapiRouteConfig,
    ...contentRouteConfig,
    ...helpRouteConfig,
    ...financeSettingsRouteConfig,
    ...newsRouteConfig,
    ...newsCategoriesRouteConfig,
    ...newsParametersRouteConfig,
    ...commentsRouteConfig,

    ...dashBoardConfigs,
    ...appsConfig,
    ...thirdPartyConfigs,
    ...extraPagesConfigs,
    ...ecommerceConfig,
    ...muiComponentConfigs,
    ...userPagesConfig,
    ...userListConfig,
  ],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs,
};

export { authorizedStructure, unAuthorizedStructure, anonymousStructure };
