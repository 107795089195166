import React from "react";

export const layoutConfigs = [
  {
    path: "/mui/layout/box",
    component: React.lazy(() => import("./Box")),
  },
  {
    path: "/mui/layout/container",
    component: React.lazy(() => import("./Container")),
  },
  {
    path: "/mui/layout/Grid",
    component: React.lazy(() => import("./Grid")),
  },
  {
    path: "/mui/layout/Stack",
    component: React.lazy(() => import("./Stack")),
  },
  {
    path: "/mui/layout/Image-list",
    component: React.lazy(() => import("./ImageList")),
  },
];
