import React from "react";

const operatorsRouteConfig = [
  {
    path: "/crm/operators/:role?/:operator?",
    component: React.lazy(() => import("./pages/operators-list-page")),
  },
  {
    path: "/crm/roles/:id?",
    component: React.lazy(() => import("./pages/roles-list-page")),
  },
];

export default operatorsRouteConfig;
