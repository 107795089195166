import React from "react";

const emailsRouteConfig = [
  {
    path: "/crm/email-templates/:id?",
    component: React.lazy(() => import("./pages/emails-page")),
  },
];

export default emailsRouteConfig;
