import { gql } from "@apollo/client";
import type { GraphqlErrorType } from "application/graphql/graphql-types";

export interface LogoutOperatorMutation {
  logoutOperator:
    | {
        __typename: "LogoutOperator";
        operator: {
          id: string;
        };
      }
    | GraphqlErrorType;
}

export interface LogoutOperatorMutationVars {}

const LOGOUT_OPERATOR = gql`
  mutation LogoutOperator {
    logoutOperator {
      __typename
      ... on LogoutOperator {
        operator {
          id
        }
      }
      ... on Error {
        error
        warnings {
          key
          warning
        }
      }
    }
  }
`;

export default LOGOUT_OPERATOR;
