import React from "react";

export const labConfigs = [
  {
    path: "/mui/lab/date-picker",
    component: React.lazy(() => import("./DatePicker")),
  },
  {
    path: "/mui/lab/date-range-picker",
    component: React.lazy(() => import("./DateRangePicker")),
  },
  {
    path: "/mui/lab/date-time-picker",
    component: React.lazy(() => import("./DateTimePicker")),
  },
  {
    path: "/mui/lab/time-picker",
    component: React.lazy(() => import("./TimePicker")),
  },
  {
    path: "/mui/lab/masonry",
    component: React.lazy(() => import("./Masonry")),
  },
  {
    path: "/mui/lab/timeline",
    component: React.lazy(() => import("./Timeline")),
  },
  {
    path: "/mui/lab/trap-focus",
    component: React.lazy(() => import("./TrapFocus")),
  },
  {
    path: "/mui/lab/tree-view",
    component: React.lazy(() => import("./TreeView")),
  },
];
