import React from "react";

export const betapiRouteConfig = [
  {
    path: "/betapi/events/:sport?/:tournament?",
    component: React.lazy(() => import("./pages/betapi-events-page")),
  },
  {
    path: "/betapi/tournaments/:sport?/:tournament?",
    component: React.lazy(() => import("./pages/betapi-tournaments-page")),
  },
  {
    path: "/betapi/template/:sport?/:tournament?",
    component: React.lazy(() => import("./pages/template-tournament-page")),
  },
];
