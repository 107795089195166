import React from "react";

export const feedbackConfigs = [
  {
    path: "/mui/feedback/alert",
    component: React.lazy(() => import("./Alert")),
  },
  {
    path: "/mui/feedback/backdrop",
    component: React.lazy(() => import("./Backdrop")),
  },
  {
    path: "/mui/feedback/dialog",
    component: React.lazy(() => import("./Dialog")),
  },
  {
    path: "/mui/feedback/progress",
    component: React.lazy(() => import("./Progress")),
  },
  {
    path: "/mui/feedback/skeleton",
    component: React.lazy(() => import("./Skeleton")),
  },
  {
    path: "/mui/feedback/snackbars",
    component: React.lazy(() => import("./Snackbar")),
  },
];
