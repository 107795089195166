import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import { AuthUser } from "../../../../types/models/AuthUser";
import {
  fetchError,
  fetchStart,
  fetchSuccess,
} from "../../../../redux/actions";
import SIGNIN_OPERATOR, {
  SigninOperatorMutation,
  SigninOperatorMutationVars,
} from "../graphql/signin-operator";
import GET_MY_OPERATOR, {
  GetMyOperatorMutation,
  GetMyOperatorMutationVars,
} from "../graphql/get-my-operator";
import LOGOUT_OPERATOR, {
  LogoutOperatorMutation,
  LogoutOperatorMutationVars,
} from "../graphql/logout-operator";

interface JWTAuthContextProps {
  user: AuthUser | null;
  isAuthenticated: boolean;
  isLoading: boolean;
}

interface SignInProps {
  email: string;
  password: string;
}

interface JWTAuthActionsProps {
  signInUser: (
    data: SignInProps
  ) => Promise<{ email?: string; password?: string } | null>;
  logout: () => Promise<void>;
}

const JWTAuthContext = createContext<JWTAuthContextProps>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
});
const JWTAuthActionsContext = createContext<JWTAuthActionsProps>({
  signInUser: async () => null,
  logout: async () => {},
});

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

interface JWTAuthAuthProviderProps {
  children: ReactNode;
}

const JWTAuthAuthProvider: React.FC<JWTAuthAuthProviderProps> = ({
  children,
}) => {
  const [jwtAuthData, setJWTAuthData] = useState<JWTAuthContextProps>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const dispatch = useDispatch();

  const { loading, data: getMyOperatorData } = useQuery<
    GetMyOperatorMutation,
    GetMyOperatorMutationVars
  >(GET_MY_OPERATOR);

  const [signinOperator] = useMutation<
    SigninOperatorMutation,
    SigninOperatorMutationVars
  >(SIGNIN_OPERATOR, { refetchQueries: [GET_MY_OPERATOR] });

  const [logoutOperator] = useMutation<
    LogoutOperatorMutation,
    LogoutOperatorMutationVars
  >(LOGOUT_OPERATOR, { refetchQueries: [GET_MY_OPERATOR] });

  useEffect(() => {
    if (loading) {
      setJWTAuthData({
        user: null,
        isLoading: true,
        isAuthenticated: false,
      });
      return;
    }

    if (getMyOperatorData?.getMyOperator.__typename === "GetMyOperator") {
      const { operator, role, abilities } = getMyOperatorData.getMyOperator;
      setJWTAuthData({
        user: operator
          ? {
              id: operator.id,
              email: operator.email,
              displayName: operator.name,
              role: role.name,
              abilities,
            }
          : null,
        isLoading: false,
        isAuthenticated: !!operator,
      });
    }
  }, [loading, getMyOperatorData]);

  const signInUser = async ({
    email,
    password,
  }: SignInProps): Promise<{ email?: string; password?: string } | null> => {
    dispatch(fetchStart());

    const { data, errors } = await signinOperator({
      variables: { email, password },
    });

    if (errors) {
      dispatch(fetchError(errors.map((item) => item.message).join(", ")));
      return null;
    }

    if (!data) {
      dispatch(fetchError("Something went wrong"));
      return null;
    }

    dispatch(fetchSuccess());

    if (data.signinOperator.__typename === "Error") {
      if (data.signinOperator.warnings) {
        return data.signinOperator.warnings.reduce(
          (prev, cur) => ({ ...prev, [cur.key]: cur.warning }),
          {}
        );
      }

      return {
        email: data.signinOperator.error,
        password: data.signinOperator.error,
      };
    }

    return null;
  };

  const logout = async () => {
    dispatch(fetchStart());

    const { data, errors } = await logoutOperator();

    if (errors) {
      dispatch(fetchError(errors.map((item) => item.message).join(", ")));
      return;
    }

    if (!data) {
      dispatch(fetchError("Something went wrong"));
      return;
    }

    if (data.logoutOperator.__typename === "Error") {
      dispatch(fetchError(data.logoutOperator.error));
      return;
    }

    dispatch(fetchSuccess());
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...jwtAuthData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signInUser,
          logout,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;
