import React from "react";

export const eventsRouteConfig = [
  {
    path: "/events/sports/:id?",
    component: React.lazy(() => import("./pages/sports-list-page")),
  },
  {
    path: "/events/list/:sport?/:action?/:tournament?",
    component: React.lazy(() => import("./pages/events-list-page")),
  },
  {
    path: "/events/opponents/:sport?/:opponent?",
    component: React.lazy(() => import("./pages/opponents-list-page")),
  },
  {
    path: "/events/draft/:sport?/:action?/:tournament?",
    component: React.lazy(() => import("./pages/draft-events-list-page")),
  },
];
