import React from "react";

export const dataGridConfigs = [
  {
    path: "/mui/data-grid/overview",
    component: React.lazy(() => import("./Overview")),
  },
  {
    path: "/mui/data-grid/layout",
    component: React.lazy(() => import("./Layout")),
  },
  {
    path: "/mui/data-grid/columns",
    component: React.lazy(() => import("./Columns")),
  },
  {
    path: "/mui/data-grid/rows",
    component: React.lazy(() => import("./Rows")),
  },
  {
    path: "/mui/data-grid/editing",
    component: React.lazy(() => import("./Editing")),
  },
  {
    path: "/mui/data-grid/sorting",
    component: React.lazy(() => import("./Sorting")),
  },
  {
    path: "/mui/data-grid/filtering",
    component: React.lazy(() => import("./Filtering")),
  },
  {
    path: "/mui/data-grid/pagination",
    component: React.lazy(() => import("./Pagination")),
  },
  {
    path: "/mui/data-grid/selection",
    component: React.lazy(() => import("./Selection")),
  },
  {
    path: "/mui/data-grid/selection",
    component: React.lazy(() => import("./Selection")),
  },
  {
    path: "/mui/data-grid/events",
    component: React.lazy(() => import("./Events")),
  },
  {
    path: "/mui/data-grid/export",
    component: React.lazy(() => import("./Export")),
  },
  {
    path: "/mui/data-grid/components",
    component: React.lazy(() => import("./Components")),
  },
  {
    path: "/mui/data-grid/styling",
    component: React.lazy(() => import("./Styling")),
  },
  {
    path: "/mui/data-grid/localization",
    component: React.lazy(() => import("./Localization")),
  },
  {
    path: "/mui/data-grid/scrolling",
    component: React.lazy(() => import("./Scrolling")),
  },
  {
    path: "/mui/data-grid/virtualization",
    component: React.lazy(() => import("./Virtualization")),
  },
  {
    path: "/mui/data-grid/accessibility",
    component: React.lazy(() => import("./Accessibility")),
  },
];
