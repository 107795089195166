import React from "react";

export const financeSettingsRouteConfig = [
  {
    path: "/finance/pari",
    component: React.lazy(() => import("./pages/finance-pari-page")),
  },
  {
    path: "/finance/live",
    component: React.lazy(() => import("./pages/finance-live-page")),
  },
];
