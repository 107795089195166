import { gql } from "@apollo/client";
import type { GraphqlErrorType } from "application/graphql/graphql-types";

export interface SigninOperatorMutation {
  signinOperator:
    | {
        __typename: "SigninOperator";
        y: {
          id: string;
        };
      }
    | GraphqlErrorType;
}

export interface SigninOperatorMutationVars {
  email: string;
  password: string;
}

const SIGNIN_OPERATOR = gql`
  mutation SigninOperator($email: String!, $password: String!) {
    signinOperator(email: $email, password: $password) {
      __typename
      ... on SigninOperator {
        operator {
          id
        }
      }
      ... on Error {
        error
        warnings {
          key
          warning
        }
      }
    }
  }
`;

export default SIGNIN_OPERATOR;
