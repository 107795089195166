export const checkPermission = (
  userAbilities?: { action: string; resource: string }[],
  routeAbility?: { action: string; resource: string }
) => {
  if (!userAbilities) {
    return false;
  }

  if (!routeAbility) {
    return true;
  }

  const ability = userAbilities.find(
    (item) =>
      item.action === routeAbility.action &&
      item.resource === routeAbility.resource
  );

  return !!ability;
};
